import React, { useEffect, useState } from "react";
import { AppSettings } from "./features/appSettings/AppSettings";
import {
  assignAttributes,
  assignAuth,
  assignToken,
  logout,
  selectHasAuth,
} from "./features/auth/authSlice";
import { Box, Container, StyledEngineProvider, ThemeProvider } from "@mui/material";
import { checkSession } from "./utils/api";
import { EncryptStorage } from "encrypt-storage";
import { LicenseInfo } from "@mui/x-license-pro";
import { Login } from "./features/auth/Login";
import { MainView } from "./components/MainView";
import { resetPanel } from "./features/panels/panelSlice";
import { selectCameraOpen } from "./features/scanner/cameraSlice";
import { theme } from "./styles/MuiTheme";
import { useSelector, useDispatch } from "react-redux";
import CameraScan from "./features/scanner/CameraScan";
import CssBaseline from "@mui/material/CssBaseline";
import TwoFactorAuthPage from "./features/auth/TwoFactorAuthPage";

export const encryptStorage = new EncryptStorage("smickThogExcess", {
  prefix: "@pwa",
  storageType: "localStorage",
});

function logUserIn({ user = {}, token = "", callback = () => {} }) {
  // const dispatch = useDispatch();
  const { email = "", password = "" } = user;
  encryptStorage.setItem("email", email);
  encryptStorage.setItem("password", password);
  localStorage.setItem("token", token);

  // callback should be assignAuth(true)
  callback();
  // dispatch(assignAuth(true));
}

function Auth() {
  // Setting the licence key for MaterialUi
  LicenseInfo.setLicenseKey(
    "4c348c947cbab5a346354780d4d2933eTz00OTM4NSxFPTE2OTI0NzM0MDU5MjQsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
  );

  const dispatch = useDispatch();
  const [okToLogout, setOkToLogout] = useState(false);

  function handleLogout(event) {
    event.preventDefault();
    console.log("setting ok to logout");
    window.localStorage.removeItem("token");
    setOkToLogout(true);
  }

  useEffect(() => {
    if (okToLogout === true) {
      console.log("dispatching logout");
      setOkToLogout(false);
      dispatch(logout());
      dispatch(resetPanel());
    }
  }, [okToLogout, dispatch]);

  const cameraOpen = useSelector(selectCameraOpen);

  return (
      <Container component="main" maxWidth="xl" sx={{ alignItems: "center" }}>
        {cameraOpen ? (
          <CameraScan />
        ) : (
          <div>
            <Box
              maxWidth="xl"
              sx={{
                padding: "20px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  marginTop: 8,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <CssBaseline />
                <MainView
                  dispatch={dispatch}
                  handleLogout={handleLogout}
                  logUserIn={logUserIn}
                />
              </Box>
            </Box>
          </div>
        )}
      </Container>
  );
}

function App(props) {
  const apiUrl = process.env.REACT_APP_API_ENDPOINT;
  const dispatch = useDispatch();
  const hasAuth = useSelector(selectHasAuth);
  const path = window.location.pathname;

  useEffect(() => {
    const tokenFromLocalStorage = localStorage.getItem("token");
    if (tokenFromLocalStorage) {
      checkSession(tokenFromLocalStorage).then((res) => {
        if (res.success) {
          dispatch(assignAuth(true));
          dispatch(assignToken(res.token));
          dispatch(assignAttributes(res));
        } else {
          // If the token is bad we boot them back to the login screen
          window.localStorage.removeItem("token");
          dispatch(assignAuth(false));
        }
      });
    } else {
      // No need to perform this check if we are on the /2faAuth page
      if (path !== "/2faAuth") {
        dispatch(assignAuth(false));
      }
    }
  });

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
      <AppSettings />
      {path === "/2faAuth" ? (
        <TwoFactorAuthPage apiUrl={apiUrl} logUserIn={logUserIn} />
      ) : hasAuth ? (
        <div>
          <Auth />
        </div>
      ) : (
        <Login apiUrl={apiUrl} logUserIn={logUserIn} />
      )}

      {/* Version */}
      <Box
        sx={{
          bottom: "0",
          color: "rgba(109,110,112,.3)",
          display: "flex",
          fontSize: "smaller",
          justifyContent: "center",
          position: "absolute",
          width: "100%",
        }}
      >
        VERSION 2.2.3
      </Box>
    </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
